import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

import Layout from '../components/layout'

const IndexPage = () => {
   const [currentImage, setCurrentImage] = React.useState(0)
   const [viewerIsOpen, setViewerIsOpen] = React.useState(false)

   const openLightbox = React.useCallback((event, { photo, index }) => {
      setCurrentImage(index)
      setViewerIsOpen(true)
   }, [])

   const closeLightbox = () => {
      setCurrentImage(0)
      setViewerIsOpen(false)
   }

   const { allFile } = useStaticQuery(
      graphql`
         query {
            allFile(
               sort: { fields: name, order: DESC }
               filter: { relativeDirectory: { eq: "slides" } }
            ) {
               edges {
                  node {
                     id
                     name
                     childImageSharp {
                        gatsbyImageData(layout: FIXED)
                     }
                  }
               }
            }
         }
      `
   )

   const photos = allFile.edges.map(x => {
      return {
         height: x.node.childImageSharp.gatsbyImageData.height,
         width: x.node.childImageSharp.gatsbyImageData.width,
         srcset: x.node.childImageSharp.gatsbyImageData.images.fallback.srcSet,
         src: x.node.childImageSharp.gatsbyImageData.images.fallback.src,
         caption: 'x.title',
      }
   })

   return (
      <Layout>
         <div className="mx-auto max-w-10xl" styles={'max-width: 1200px'}>
            {/*<h1 className="pageTitle">Το Ιατρείο</h1>*/}
            <div className="pageContent">
               <div>
                  <Gallery photos={photos} onClick={openLightbox} />
                  <ModalGateway>
                     {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                           <Carousel
                              currentIndex={currentImage}
                              views={photos.map(x => {
                                 return {
                                    ...x,
                                    srcset: x.srcSet,
                                    src: x.src,
                                    caption: 'x.title',
                                 }
                              })}
                           />
                        </Modal>
                     ) : null}
                  </ModalGateway>
               </div>
            </div>
         </div>
      </Layout>
   )
}

export default IndexPage


export function Head() {
   return (
      <title>Ιατρείο | Σαρδαρίδης Φιλάρετος Ανδρολόγος Ουρολόγος</title>
   )
}